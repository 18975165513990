import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { MatchesEnum, MatchListType } from '../../types/candidate';
import { CandidateMatchRequestType, CandidateMatchResponseType } from '../../types/company';
import { companyService } from '../../lib/api/company';
import PageLoader from '../loader';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux';
import { routes } from '../../containers/routes/routes-names';
import history from '../../history';

type Props = {
    show: boolean;
    handleClose: (key: boolean) => void;
    candidateData: MatchListType | null;
    showEventButton: boolean;
    matchType: number;
};

const MatchModal: React.FC<Props> = ({ show, handleClose, candidateData, showEventButton, matchType }) => {
    const [data, setData] = useState<CandidateMatchResponseType[]>();
    const [loading, setLoading] = useState(true);
    const [showSidebarTab, setShowSidebarTab] = useState(true);
    const [email, setEmail] = useState('');
    const { user } = useSelector((state: RootState) => state.user);

    const handleCloseModal = () => handleClose(false);

    const emailArray = ['saikiran.potlapalli@wallero.com', 'saikiran.potlapalli@offerx.net', 'bujzi2898@outlook.com'];

    function isEmailInArray(email: string | undefined, array: string[]) {
        return array.indexOf(email?.toLowerCase() || '') !== -1;
    }

    useEffect(() => {
        if (isEmailInArray(user?.email, emailArray)) {
            setShowSidebarTab(false);
        } else {
            setShowSidebarTab(true);
        }
    }, [email]);

    const getList = useCallback(async () => {
        try {
            sessionStorage.clear();
            !loading && setLoading(true);
            const params: CandidateMatchRequestType = {
                matchType,
                email: candidateData?.email || '',
                fullName: candidateData?.candidateName || '',
                phoneNumber: candidateData?.phone || '',
                candidateId: candidateData?.candidateId || '',
            };

            const { resultObject } = await companyService.getCandidateMatchList(params);
            setData(resultObject);
        } catch (err) {
        } finally {
            setLoading(false);
        }
    }, [loading, candidateData]);

    useEffect(() => {
        if (candidateData) {
            (async function () {
                await getList();
            })();
        }
    }, [candidateData]);

    return (
        <>
            <Modal size="lg" show={show} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Match Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="fs-12 fw-600 mb-3">Existing Candidate:</div>
                    <div className="row">
                        <div className="col-md-3">
                            <div className="fs-12 fw-600">Name:</div>
                            <div className="fs-14 text-ellipsis">{candidateData?.candidateName || 'NA'}</div>
                        </div>
                        <div className="col-md-3">
                            <div className="fs-12 fw-600">Email:</div>
                            <div className="fs-14 text-ellipsis">{candidateData?.email || 'NA'}</div>
                        </div>
                        <div className="col-md-3">
                            <div className="fs-12 fw-600">Contact No.:</div>
                            <div className="fs-14">{candidateData?.phone || 'NA'}</div>
                        </div>
                        <div className="col-md-3">
                            <div className="fs-12 fw-600">Source:</div>
                            <div className="fs-14 lt-text-primary-alt">OfferX</div>
                        </div>
                    </div>
                    <hr />
                    <div>
                        <div className="row">
                            {loading ? (
                                <PageLoader />
                            ) : (
                                <>
                                    {data && data.length > 0 ? (
                                        data.map((items, index) => {
                                            return (
                                                <div className="col-md-4" key={index}>
                                                    <div className="match-cards mb-3">
                                                        <div className="fs-12 mb-2 text-center fw-600">
                                                            Punched from:{' '}
                                                            <span className="lt-text-primary-alt">OfferX</span>
                                                        </div>
                                                        <div className="d-flex justify-content-between fs-12 align-items-center">
                                                            <div className="d-flex align-items-center">
                                                                <input
                                                                    type="checkbox"
                                                                    name=""
                                                                    id={`matchCheck-${index}`}
                                                                    className="cursor-pointer"
                                                                />
                                                                <label
                                                                    htmlFor={`matchCheck-${index}`}
                                                                    className="ms-1 cursor-pointer"
                                                                >
                                                                    {items?.fullName || 'NA'}
                                                                </label>
                                                            </div>
                                                            <div>
                                                                {items.isFullNameMatching ? (
                                                                    <span className="lt-text-success-alt">Match</span>
                                                                ) : (
                                                                    <span className="lt-text-error">Mismatch</span>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="d-flex justify-content-between fs-12 align-items-center">
                                                            <div className="text-crop">
                                                                <div
                                                                    className="big-text"
                                                                    style={{ top: '-9px' }}
                                                                    title={items.email || 'NA'}
                                                                >
                                                                    {items.email || 'NA'}
                                                                </div>
                                                            </div>
                                                            <div>
                                                                {items.isEmailMatching ? (
                                                                    <span className="lt-text-success-alt">Match</span>
                                                                ) : (
                                                                    <span className="lt-text-error">Mismatch</span>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="d-flex justify-content-between fs-12 align-items-center">
                                                            <div>
                                                                <div>{items.phone || 'NA'}</div>
                                                            </div>
                                                            <div>
                                                                {items.isPhoneNumberMatching ? (
                                                                    <span className="lt-text-success-alt">Match</span>
                                                                ) : (
                                                                    <span className="lt-text-error">Mismatch</span>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="text-center mt-2">
                                                            <input
                                                                type="checkbox"
                                                                name=""
                                                                id={`mainMatchCheck-${index}`}
                                                                className="cursor-pointer"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    ) : (
                                        <div className="col-md-4">
                                            <div className="match-cards mb-3">
                                                <div className="fs-12 mb-2 text-center fw-600">
                                                    Punched from: <span className="lt-text-primary-alt">OfferX</span>
                                                </div>
                                                <div className="d-flex justify-content-between fs-12 align-items-center">
                                                    <div className="d-flex align-items-center">
                                                        <input
                                                            type="checkbox"
                                                            name=""
                                                            id="matchCheck-single"
                                                            className="cursor-pointer"
                                                            checked
                                                        />
                                                        <label
                                                            htmlFor="matchCheck-single"
                                                            className="ms-1 cursor-pointer"
                                                        >
                                                            {candidateData?.candidateName || 'NA'}
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <span className="lt-text-success-alt">Match</span>
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-between fs-12 align-items-center">
                                                    <div>
                                                        <div>{candidateData?.email || 'NA'}</div>
                                                    </div>
                                                    <div>
                                                        <span className="lt-text-success-alt">Match</span>
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-between fs-12 align-items-center">
                                                    <div>
                                                        <div>{candidateData?.phone || 'NA'}</div>
                                                    </div>
                                                    <div>
                                                        <span className="lt-text-success-alt">Match</span>
                                                    </div>
                                                </div>
                                                <div className="text-center mt-2">
                                                    <input
                                                        type="checkbox"
                                                        name=""
                                                        id="mainMatchCheck-single"
                                                        className="cursor-pointer"
                                                        checked
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </Modal.Body>
                {showEventButton && showSidebarTab && (
                    <Modal.Footer className="justify-content-center">
                        <button className="btn btn-outline-danger" onClick={handleCloseModal}>
                            Reject Match
                        </button>
                        <button className="btn btn-outline-success" onClick={handleCloseModal}>
                            Confirm Match
                        </button>
                    </Modal.Footer>
                )}
            </Modal>
        </>
    );
};

export default MatchModal;
