import { ApiResponseType } from './api';
import { GenderEnum, HighestEducationEnum, UserRoleEnum } from './auth';
import { SortTypeEnum } from './index';
import { OfferReviewStateEnum } from './offer';
import { CompanyReviewType, DiscardEnum } from './review';
import { SuspendStatusEnum, SuspensionDecisionEnum } from './suspension';

export enum OfferNextActionEnum {
    releaseAnOffer,
    reOffer,
    noActionAvailable,
}

export enum MatchesEnum {
    Match,
    MisMatch,
    MostLikelyMatch,
    LessLikelyMatch,
}
export enum CandidateInviteStatusEnum {
    joined,
    uploaded,
    pending,
    deleted,
    invited,
}

export type CandidateAdvancedRequestType = {
    Start: number;
    Limit: number;
    'FirstName.FilterValue'?: string;
    'FirstName.SortType'?: SortTypeEnum;
    'LastName.FilterValue'?: string;
    'LastName.SortType'?: SortTypeEnum;
    'JobTitle.FilterValue'?: string;
    'JobTitle.SortType'?: SortTypeEnum;
    'CityDistrict.FilterValue'?: string;
    'CityDistrict.SortType'?: SortTypeEnum;
    'State.FilterValue'?: string;
    'State.SortType'?: SortTypeEnum;
    'InstitutionName.FilterValue'?: string;
    'InstitutionName.SortType'?: SortTypeEnum;
    'Email.FilterValue'?: string;
    'Email.SortType'?: SortTypeEnum;
    'Phone.FilterValue'?: string;
    'Phone.SortType'?: SortTypeEnum;
};

export type CandidateGlobalRequestType = {
    Start: number;
    Limit: number | null;
    'CandidateFirstName.FilterValue'?: string;
    'CandidateFirstName.SortType'?: SortTypeEnum;
    'CandidateLastName.FilterValue'?: string;
    'CandidateLastName.SortType'?: SortTypeEnum;
    'CandidateCityDistrict.FilterValue'?: string;
    'CandidateCityDistrict.SortType'?: SortTypeEnum;
    'CandidateState.FilterValue'?: string;
    'CandidateState.SortType'?: SortTypeEnum;
    'ReportedDate.FilterValue'?: string;
    'ReportedDate.SortType'?: SortTypeEnum;
    'ReportedByCompanyName.FilterValue'?: string;
    'ReportedByCompanyName.SortType'?: SortTypeEnum;
    'CandidateSuspendReason.FilterValue'?: string;
    'CandidateSuspendReason.SortType'?: SortTypeEnum;
};

export type CandidateInviteAdvancedRequestType = {
    Start: number;
    Limit: number;
    'FirstName.FilterValue'?: string;
    'FirstName.SortType'?: SortTypeEnum;
    'LastName.FilterValue'?: string;
    'LastName.SortType'?: SortTypeEnum;
    'FullName.FilterValue'?: string;
    'FullName.SortType'?: SortTypeEnum;
    'Email.FilterValue'?: string;
    'Email.SortType'?: SortTypeEnum;
    'Phone.FilterValue'?: string;
    'Phone.SortType'?: SortTypeEnum;
    'InvitedDate.FilterValue'?: string;
    'InvitedDate.SortType'?: SortTypeEnum;
    'CandidateStatus.FilterValue'?: string;
    'CandidateStatus.SortType'?: SortTypeEnum;
};

export type CandidateInviteGlobalRequestType = {
    Start: number;
    Limit: number;
    'FirstName.FilterValue'?: string;
    'FirstName.SortType'?: SortTypeEnum;
    'LastName.FilterValue'?: string;
    'LastName.SortType'?: SortTypeEnum;
    'FullName.FilterValue'?: string;
    'FullName.SortType'?: SortTypeEnum;
    'Email.FilterValue'?: string;
    'Email.SortType'?: SortTypeEnum;
    'Phone.FilterValue'?: string;
    'Phone.SortType'?: SortTypeEnum;
    'InvitedDate.FilterValue'?: string;
    'InvitedDate.SortType'?: SortTypeEnum;
    'CandidateStatus.FilterValue'?: string;
    'CandidateStatus.SortType'?: SortTypeEnum;
};

export type MatchListRequestType = {
    Start: number;
    Limit: number;
    'CandidateName.FilterValue'?: string;
    'CandidateName.SortType'?: SortTypeEnum;
    'MatchDetails.FilterValue'?: string;
    'MatchDetails.SortType'?: SortTypeEnum;
    'DataPunchDate.FilterValue'?: string;
    'DataPunchDate.SortType'?: SortTypeEnum;
};

export type MatchRequestType = {
    matchType: number;
};

export type CandidateProfileType = {
    id: string;
    active: boolean;
    email: string;
    role: UserRoleEnum;
    firstName: string;
    middleName: string;
    lastName: string;
    avatarUrl: string;
    bannerUrl: string;
    jobTitle: string;
    cityDistrict: string;
    state: string;
    website: string;
    highestEducation: HighestEducationEnum | null;
    institutionName: string | null;
    graduationYear: number;
    candidateReviews: CompanyReviewType[];
    reviewsCount: number;
    rating: {
        value: number;
    };
    contractComplianceQuantity: number;
    contractViolationQuantity: number;
    aboutMe: string | null;
    phone: string | null;
    candidateNumber: number;
    resumeFileUrl: string;
    offerReviewState: OfferReviewStateEnum;
    reviewId: string | null;
    offerId: string | null;
    reOfferId: string | null;
    allCompaniesAcceptedOffersCount: number;
    currentCompanyLastEventShortText: string | null;
    currentCompanyLastEventDetailedText: string | null;
    currentCompanyOfferNextAvailableAction: OfferNextActionEnum;
    dob: Date;
    gender: GenderEnum;
    aadhar: string;
    country: string;
    suspendStatus: SuspendStatusEnum;
    suspendReasonOtherText: string;
    suspendReasons: string[];
    userCountryCode: string;
};

export type EditCandidateFormType = {
    firstName: string;
    middleName: string;
    lastName: string;
    avatarUrl: string;
    jobTitle: string;
    cityDistrict: string;
    state: string;
    aboutMe: string;
    website: string;
    phone: string;
    highestEducation: HighestEducationEnum | string | null;
    institutionName: string;
    graduationYear: string;
    dob: Date;
    gender: GenderEnum;
    aadhar: string;
    country: string;
};

export type UpdateCandidateRequestType = Omit<EditCandidateFormType, 'graduationYear'> & {
    graduationYear: number;
};

export type CandidateListType = {
    id: string;
    firstName: string;
    lastName: string;
    fullName: string;
    jobTitle: string;
    cityDistrict: string;
    state: string;
    institutionName: string | null;
    avatarUrl: string | null;
    rating: number;
    reviewsCount: number;
    aboutMe: string | null;
    phone: string | null;
    email: string;
    website: string;
    highestEducation: HighestEducationEnum | null;
    graduationYear: number;
    allCompaniesAcceptedOffersCount: number;
    currentCompanyLastEventShortText: string | null;
    currentCompanyLastEventDetailedText: string | null;
    currentCompanyOfferNextAvailableAction: OfferNextActionEnum;
    reOfferId: string | null;
    location: string;
};

export type CandidateInviteListType = {
    candidateId?: string;
    candidateStatus: CandidateInviteStatusEnum;
    email: string;
    firstName: string;
    invitationId?: string;
    invitedDate: Date;
    isInvited?: boolean;
    lastName: string;
    phone: string;
};

export type MatchListType = {
    candidateName: string;
    matchDetails: string;
    dataPunchDate: Date;
    avatarUrl: string;
    email?: string;
    phone?: string;
    punchedBy?: string;
    candidateId: string;
};

export type CandidateListResponseType = Omit<ApiResponseType, 'resultObject'> & {
    resultObject: {
        items: CandidateListType[];
        hasMore: boolean;
        count: number;
    };
};

export type CandidateInviteListResponseType = Omit<ApiResponseType, 'resultObject'> & {
    resultObject: {
        items: CandidateInviteListType[];
        hasMore: boolean;
        count: number;
    };
};

export type MatchListResponseType = Omit<ApiResponseType, 'resultObject'> & {
    resultObject: MatchListType[];
};

export type CandidateProfileResponseType = Omit<ApiResponseType, 'resultObject'> & {
    resultObject: CandidateProfileType;
};

export type ReportFromCandidateType = {
    id: string;
    companyId: string;
    reportedByCompanyName: string;
    candidateId: string;
    candidateFirstName: string;
    candidateLastName: string;
    candidateFullName: string;
    candidateCityDistrict: string;
    candidateState: string;
    reportedDate: Date;
    candidateSuspendReason: string[];
    suspendReasonOtherText: string;
    candidateAvatarUrl: string;
    companyAvatarUrl: string[];
    isFlagged: boolean;
    suspensionDecision: SuspensionDecisionEnum;
    candidateJobTitle: string;
    disCardStatus: DiscardEnum;
    suspendReasons: string[];
    candidateSuspendReasonText: string;
    candidateSuspensionStatus: SuspendStatusEnum;
};

export type GetAllCompanyReportFromCandidateResponseType = Omit<ApiResponseType, 'resultObject'> & {
    resultObject: {
        items: ReportFromCandidateType[];
        hasMore: boolean;
        count: number;
    };
};

export type updateFlagType = {
    candidateReportFromCompanyId: string;
    flagValue: boolean;
};

export type candidateCountType = {
    totalCandidatesCount: number;
    totalCandidatesGrowth: number;
    activeCandidatesCount: number;
    activeCandidatesGrowth: number;
    suspendedCandidatesCount: number;
    suspendedCandidatesGrowth: number;
    reportedByCompaniesCandidatesCount: number;
    reportedByCompaniesCandidatesGrowth: number;
};

export type candidatetResponseType = Omit<ApiResponseType, 'resultObject'> & {
    resultObject: candidateCountType;
};

export type ScreenXCandidateListResponseType = Omit<ApiResponseType, 'result'> & {
    result: {
        items: ScreenXCandidateListType[];
        hasMore: boolean;
        count: number;
    };
};

export type ScreenXCandidateListType = {
    id: string;
    firstName: string;
    lastName: string;
    fullName: string;
    jobTitle: string;
    city: string;
    state: string;
    institutionName: string | null;
    avatarUrl: string | null;
    rating: number;
    reviewsCount: number;
    aboutMe: string | null;
    phone: string | null;
    email: string;
    website: string;
    highestEducation: HighestEducationEnum | null;
    graduationYear: number;
    allCompaniesAcceptedOffersCount: number;
    currentCompanyLastEventShortText: string | null;
    currentCompanyLastEventDetailedText: string | null;
    currentCompanyOfferNextAvailableAction: OfferNextActionEnum;
    reOfferId: string | null;
    location: string;
    isActive: boolean;
};
