import React, { useState, useEffect } from 'react';
import { companyService } from '../../lib/api/company';
import { RewardCountType } from '../../types/company';
import PageLoader from '../loader';
import history from '../../history';

const Statistics = ({ companyId }: any) => {
    const [countsData, setCountsData] = useState<RewardCountType | null>(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (async function () {
            try {
                setLoading(true);
                const { resultObject: data } = await companyService.getRewardCount({ companyId });
                setCountsData(data);
            } catch (error) {
                console.log('Company count error', error);
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    return (
        <>
            {countsData ? (
                <div className="row mt-4">
                    <div className="col-lg-3 col-xl-3">
                        <div className="card lt-shape-card p-3 mb-4">
                            <div className="fw-600 fs-14">
                                <div className="lt-dashboard-text">
                                    Total Points Earned
                                    <i className="bi bi-info-circle lt-tooltip ms-1">
                                        <span className="lt-tooltiptext lt-shadow-md fw-400 fs-14 bottom-tip">
                                            Points earned in OfferX till date
                                        </span>
                                    </i>
                                </div>
                                <div className="fs-28 lt-dashboard-icon">
                                    <i className="lt-dashboard-text bi bi-building me-0" />
                                </div>
                            </div>
                            <div className="fw-400 fs-32 lt-text-link-primary"> {countsData.companyTotalPoints}</div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-xl-3">
                        <div className="card lt-shape-card p-3 mb-4">
                            <div className="fw-600 fs-14">
                                <div className="lt-dashboard-text">
                                    Total Data Punched
                                    <i className="bi bi-info-circle lt-tooltip ms-1">
                                        <span className="lt-tooltiptext lt-shadow-md fw-400 fs-14 bottom-tip">
                                            Total Data Punches OfferX till date
                                        </span>
                                    </i>
                                </div>
                                <div className="fs-28 lt-dashboard-icon">
                                    <i className="lt-dashboard-text bi bi-building me-0" />
                                </div>
                            </div>
                            <div className="fw-400 fs-32 lt-text-link-primary">{countsData.companyTotaDataPunched}</div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-xl-3">
                        <div className="card lt-shape-card p-3 mb-4">
                            <div className="fw-600 fs-14">
                                <div className="lt-dashboard-text">
                                    Total Offer Released
                                    <i className="bi bi-info-circle lt-tooltip ms-1">
                                        <span className="lt-tooltiptext lt-shadow-md fw-400 fs-14 bottom-tip">
                                            Total Offers Released offerx till date
                                        </span>
                                    </i>
                                </div>
                                <div className="fs-28 lt-dashboard-icon">
                                    <i className="lt-dashboard-text bi bi-building me-0" />
                                </div>
                            </div>
                            <div className="fw-400 fs-32 lt-text-link-primary">
                                {countsData.companyTotalOffersReleased}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-xl-3">
                        <div className="card lt-shape-card p-3 mb-4">
                            <div className="fw-600 fs-14">
                                <div className="lt-dashboard-text">
                                    Total Reviews Submitted
                                    <i className="bi bi-info-circle lt-tooltip ms-1">
                                        <span className="lt-tooltiptext lt-shadow-md fw-400 fs-14">
                                            Total reviews submitted offerx till date
                                        </span>
                                    </i>
                                </div>
                                <div className="fs-28 lt-dashboard-icon">
                                    <i className="lt-dashboard-text bi bi-people me-0" />
                                </div>
                            </div>
                            <div className="fw-400 fs-32 lt-text-link-primary">
                                {countsData.companyTotaReviewsSubmitted}
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default Statistics;
