export const urlPrefix = '/';

export const routes = {
    main: `${urlPrefix}`,
    dashboard: `${urlPrefix}dashboard`,
    companyList: `${urlPrefix}companies/company-list`,
    companyRewardsList: `${urlPrefix}companies/rewards`,
    hrmsCompanyList: `${urlPrefix}companies/hrms-company-list`,
    // companyListNew: `${urlPrefix}company-list-new`,
    reportedCompanyList: `${urlPrefix}companies/reported-companies`,
    companyRegistration: `${urlPrefix}companies/company-registration`,
    companyVerification: `${urlPrefix}companies/company-verification`,
    userManagement: `${urlPrefix}user-management`,
    CompanyView: `${urlPrefix}company-list/:id`,
    candidateList: `${urlPrefix}candidates/candidate-list`,
    reportedCandidateList: `${urlPrefix}candidates/reported-candidates`,
    candidateView: `${urlPrefix}candidate-list/:id`,
    policy: `${urlPrefix}policy`,
    affiliateUser: `${urlPrefix}affiliate/users`,
    affiliateCompanies: `${urlPrefix}affiliate/companies`,
    candidateInvite: `${urlPrefix}candidates/candidate-invitations`,
    completeMatch: `${urlPrefix}match-list/complete-matches`,
    mostLikelyMatch: `${urlPrefix}match-list/most-likely-matches`,
    lessLikelyMatch: `${urlPrefix}match-list/less-likely-matches`,
    noMatch: `${urlPrefix}match-list/no-matches`,
};
