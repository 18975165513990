import React, { useEffect, useMemo, useState } from 'react';
import TableObject, { TableColumnType, TableDataType, TableFilterType, TableSortType } from './table-object';
import Pagination from './pagination';
import { debounce } from 'lodash';

export type AsyncTableType = {
    data: TableDataType[];
    columns: TableColumnType[];
    loading: boolean;
    pageSize: number;
    setPageSize: (page: number) => void;
    totalCount?: number;
    start: number;
    setStart: (start: number) => void;
    makeSearchRequest: (filters: TableFilterType[]) => void;
    makeSortRequest: (value: TableSortType) => void;
    fixedCols?: number;
    reverseHead?: boolean;
    clearServerSearch?: boolean;
    setClearServerSearch?: (value: boolean) => void;
};

const AsyncTable: React.FC<AsyncTableType> = ({
    data,
    columns,
    reverseHead,
    fixedCols,
    loading,
    pageSize,
    setPageSize,
    totalCount,
    start,
    setStart,
    makeSearchRequest,
    makeSortRequest,
    clearServerSearch,
    setClearServerSearch,
}) => {
    const [sort, setSort] = useState<TableSortType | null>(null);
    const [inputFilter, setInputFilter] = useState<TableFilterType[]>([]);

    const [paginationArray, setPaginationArray] = useState<TableDataType[][]>([]);

    const paginationCurrent = useMemo(() => Math.floor(start / pageSize), [start, pageSize]);
    const numbersPages = useMemo(() => Math.ceil(totalCount || 0 / pageSize), [totalCount, pageSize]);

    const debouncedSearch = React.useMemo(
        () =>
            debounce((newFilters) => {
                setClearServerSearch?.(false);
                makeSearchRequest(newFilters);
            }, 750),
        [makeSearchRequest]
    );

    const handleChange = React.useCallback(
        (value: TableFilterType) => {
            const exist = inputFilter.find((item) => item.dataIndex === value.dataIndex);
            const newFilters = exist
                ? inputFilter.map((item) => (item.dataIndex === value.dataIndex ? value : item))
                : [...inputFilter, value];
            setInputFilter(newFilters);
            debouncedSearch(newFilters);
            setStart(1);
        },
        [debouncedSearch, inputFilter]
    );

    useEffect(() => {
        const fakeArray = Array(numbersPages).fill([]);
        fakeArray[paginationCurrent] = data;
        setPaginationArray(fakeArray);
    }, [numbersPages, paginationCurrent, data]);

    const objectProps = {
        sort,
        setSort: (value: TableSortType) => {
            setSort(value);
            makeSortRequest(value);
        },
        loading,
        columns,
        paginationArray,
        paginationCurrent,
        fixedCols: fixedCols || 0,
        reverseHead,
        inputFilter,
        setInputFilter: handleChange,
    };

    useEffect(() => {
        if (clearServerSearch) {
            setInputFilter([]);
        }

        return () => {
            debouncedSearch.cancel();
        };
    }, [debouncedSearch, clearServerSearch]);

    return (
        <>
            <div className="offer-table-container">
                <div className="offer-table-scroll table-responsive w-100">
                    <TableObject {...objectProps} />
                </div>
            </div>

            <Pagination
                pageSize={pageSize}
                setPageSize={setPageSize}
                start={start}
                setStart={setStart}
                paginationCurrent={paginationCurrent}
                paginationArray={paginationArray}
                handlePrevPage={() => setStart(start - pageSize)}
                handleNextPage={() => setStart(start + pageSize)}
                totalRecords={totalCount || 0}
            />
        </>
    );
};

export default AsyncTable;
