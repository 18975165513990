import React, { useCallback, useEffect, useMemo, useState } from 'react';
import avatar from '../../../assets/img/candidate-avatars-lg.png';
import PageLoader from '../../../components/loader';
import Table from '../../../components/table';
import { SortTypeEnum } from '../../../types';
import { candidateService } from '../../../lib/api/candidate';
import { MatchesEnum, MatchListRequestType, MatchListType, MatchRequestType } from '../../../types/candidate';
import MatchModal from '../../../components/match-modals/matchModal';

const LessLikelyMatchTable = () => {
    const [data, setData] = useState<MatchListType[]>([]);
    const [loading, setLoading] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [candidateData, setCandidateData] = useState<MatchListType | null>(null);

    const columns = useMemo(
        () => [
            {
                dataIndex: 'candidateName',
                title: 'Candidate Name',
                render: (item: any) => (
                    <>
                        <div className="d-flex cursor-pointer">
                            <div className="offer-table-reoffered me-2">
                                <img src={item.avatarUrl || avatar} alt="" className="avatar avatar--xs" />
                            </div>
                            <div className="text-crop" style={{ top: '6px' }}>
                                <a className="big-text text-capitalize offer-table-link" title={item.candidateName}>
                                    {item.candidateName}
                                </a>
                            </div>
                        </div>
                    </>
                ),
            },
            {
                dataIndex: 'matchDetails',
                title: 'Match Details',
                render: (item: any) => (
                    <>
                        <div className="text-ellipsis text-capitalize">
                            <span>{item.matchDetails}</span>
                        </div>
                    </>
                ),
            },
            {
                dataIndex: 'punchedByCompany',
                title: 'Punched By',
                render: (item: any) => (
                    <>
                        <div className="text-ellipsis">
                            <span>{item.punchedByCompany || 'NA'}</span>
                        </div>
                    </>
                ),
            },
            {
                dataIndex: 'dataPunchDate',
                title: 'Punch Date',
                width: '128px',
                inputFilter: (value: any) => {
                    return new Date(value).toLocaleString('en-in', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                    });
                },
                render: (item: any) =>
                    new Date(item.dataPunchDate).toLocaleString('en-in', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                    }),
            },
            {
                dataIndex: '',
                title: 'Actions',
                render: (item: any) => (
                    <>
                        <button
                            className="btn btn-outline-primary fs-14"
                            type="button"
                            onClick={() => {
                                setOpenModal(true);
                                setCandidateData(item);
                            }}
                        >
                            View
                        </button>
                    </>
                ),
            },
        ],
        []
    );

    const getList = useCallback(async () => {
        try {
            sessionStorage.clear();
            !loading && setLoading(true);
            const params: MatchRequestType = {
                matchType: MatchesEnum.LessLikelyMatch,
            };

            const { resultObject } = await candidateService.getMatchList(params);
            setData(resultObject);
        } catch (err) {
        } finally {
            setLoading(false);
        }
    }, [loading]);

    useEffect(() => {
        (async function () {
            await getList();
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <h1 className="">Less Likely Matches</h1>
                </div>
            </div>
            <div className="company-page-contener employer-table">
                {loading ? (
                    <PageLoader />
                ) : (
                    <>
                        <Table columns={columns} data={data} />
                    </>
                )}

                <MatchModal
                    show={openModal}
                    handleClose={setOpenModal}
                    candidateData={candidateData}
                    matchType={MatchesEnum.LessLikelyMatch}
                    showEventButton={true}
                />
            </div>
        </>
    );
};

export default LessLikelyMatchTable;
