import { TableFilterType, TableSortType } from '../../../components/table/table-object';
import { SortTypeEnum } from '../../../types';

export function mapFilters(filters: TableFilterType[]) {
    if (!filters.length) return {};
    let mappedFilters: { [key: string]: string } = {};
    filters.forEach((item) => {
        if (item.dataIndex === 'fullName') {
            mappedFilters['FullName.FilterValue'] = item.value;
        }
        if (item.dataIndex === 'email') {
            mappedFilters['Email.FilterValue'] = item.value;
        }
        if (item.dataIndex === 'phone') {
            mappedFilters['Phone.FilterValue'] = item.value;
        }

        // if (item.dataIndex === 'invitedDate') {
        //     mappedFilters['InvitedDate.FilterValue'] = item.value;
        // }

        if (item.dataIndex === 'invitedDate' && item.value) {
            mappedFilters['InvitedDate'] = item.value.trim();
        }

        // if (item.dataIndex === 'candidateStatus') {
        //     mappedFilters['CandidateStatus.FilterValue'] = item.value;
        // }

        // if (item.dataIndex === 'candidateStatus' && item.value) {
        //     mappedFilters['CandidateStatus'] = parseInt(item.value, 10).toString();
        // }
        if (item.dataIndex === 'candidateStatus') {
            mappedFilters['Status.FilterValue'] = item.value;
        }
    });
    return mappedFilters;
}

export function mapSort(sort: TableSortType) {
    let mappedSort: { [key: string]: SortTypeEnum } = {};
    if (sort.dataIndex === 'fullName') {
        mappedSort['FullName.SortType'] = sort.value;
    }
    if (sort.dataIndex === 'lastName') {
        mappedSort['LastName.SortType'] = sort.value;
    }
    if (sort.dataIndex === 'email') {
        mappedSort['Email.SortType'] = sort.value;
    }
    if (sort.dataIndex === 'phone') {
        mappedSort['Phone.SortType'] = sort.value;
    }
    if (sort.dataIndex === 'invitedDate') {
        mappedSort['InvitedDate.SortType'] = sort.value;
    }
    if (sort.dataIndex === 'candidateStatus') {
        mappedSort['Status.SortType'] = sort.value;
    }
    return mappedSort;
}
