import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Statistics from './statistics';
import PageLoader from '../loader';
import history from '../../history';
import Table from '../table';
import { companyService } from '../../lib/api/company';
import { SortTypeEnum } from '../../types';
import { RewardsListType, RewardsRequestType } from '../../types/company';
import AsyncTable from '../table/async';
import { mapFilters, mapSort } from './utils';

const PointsTable = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<RewardsListType[]>([]);
    const [start, setStart] = useState(1);
    const [limit, setLimit] = useState(10);
    const [filters, setFilters] = useState<{ [key: string]: string } | null>(null);
    const [sort, setSort] = useState<{ [key: string]: SortTypeEnum } | null>(null);
    const [clearServerSearch, setClearServerSearch] = useState(false);
    const [totalCount, setTotalCount] = useState(0);

    const { companyId, companyName } = history.location.state as { companyId: string; companyName: string };

    const columns = useMemo(
        () => [
            {
                dataIndex: 'serviceType',
                title: 'Offer Type',
                render: (item: any) => (
                    <>
                        <div className="text-crop">
                            <div className="big-text text-capitalize" title={item.serviceType}>
                                {item.serviceType}
                            </div>
                        </div>
                    </>
                ),
            },
            {
                dataIndex: 'date',
                title: 'Date',
                // width: '128px',
                inputFilter: (value: any) => {
                    return new Date(value).toLocaleString('en-in', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                    });
                },
                render: (item: any) =>
                    new Date(item.date).toLocaleString('en-in', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                    }),
            },
            {
                dataIndex: 'doneBy',
                title: 'Done By',
                render: (item: any) => (
                    <div className="text-crop" style={{ top: '6px' }}>
                        <div className="big-text text-capitalize" title={item.doneBy}>
                            {item?.doneBy === ' ' ? 'NA' : item?.doneBy}
                        </div>
                    </div>
                ),
            },
            {
                dataIndex: 'points',
                title: 'Points',
                render: (item: any) => (
                    <>
                        <div className="" title={item.points}>
                            {Math.abs(item.points)}{' '}
                            {item.points < 0 ? (
                                <i className="bi bi-arrow-down lt-text-error"></i>
                            ) : (
                                <i className="bi bi-arrow-up lt-text-success"></i>
                            )}
                        </div>
                    </>
                ),
            },
            {
                dataIndex: 'totalPoints',
                title: 'Total Points',
                render: (item: any) => (
                    <>
                        <div className="" title={item.totalPoints}>
                            <span>{item?.totalPoints || 0}</span>
                        </div>
                    </>
                ),
            },
        ],
        []
    );

    const getList = useCallback(async () => {
        try {
            !loading && setLoading(true);
            const params: RewardsRequestType = {
                companyId,
                Start: start,
                Limit: limit,
                ...filters,
                ...sort,
            };

            const {
                resultObject: { items, count },
            } = await companyService.getRewardsList(params);

            setData(items);
            setTotalCount(count);
        } catch (err) {
        } finally {
            setLoading(false);
        }
    }, [start, limit, filters, sort]);

    useEffect(() => {
        (async function () {
            await getList();
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [start, limit, filters, sort]);

    return (
        <>
            <div className="my-3">
                <span className="pe-2">
                    <span
                        className="cursor-pointer fw-600"
                        onClick={() => {
                            history.goBack();
                        }}
                    >
                        <i className="bi bi-chevron-left lt-text-secondary-alt me-0"></i> Back
                    </span>
                </span>
            </div>
            <div className="fs-20 fw-700">{companyName}</div>

            <Statistics companyId={companyId} />

            <button
                onClick={() => {
                    setFilters(mapFilters([]));
                    sessionStorage.clear();
                    setClearServerSearch(true);
                }}
                className="btn btn-outline-primary btn-sm me-2 sticky-left"
            >
                <i className="bi bi-funnel"></i> Clear All
            </button>
            <div className="company-page-contener rewards-table">
                {/* {loading ? <PageLoader /> : <Table columns={columns} data={data} />} */}
                <AsyncTable
                    loading={loading}
                    columns={columns}
                    data={data}
                    start={start}
                    setStart={setStart}
                    pageSize={limit}
                    clearServerSearch={clearServerSearch}
                    setClearServerSearch={setClearServerSearch}
                    setPageSize={setLimit}
                    totalCount={totalCount}
                    makeSearchRequest={(filters) => setFilters(mapFilters(filters))}
                    makeSortRequest={(value) => setSort(mapSort(value))}
                />
            </div>
        </>
    );
};

export default PointsTable;
