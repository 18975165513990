import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Table from '../../components/table';
import avatar from '../../assets/img/company-lg-icon.png';
import PageLoader from '../../components/loader';
import { invitationsService } from '../../lib/api/invitations';
import { CandidateInviteListType, CandidateInviteStatusEnum } from '../../types/candidate';
import { CandidateInviteAdvancedRequestType } from '../../types/candidate';
import { SortTypeEnum } from '../../types';
import { candidateService } from '../../lib/api/candidate';
import AsyncTable from '../../components/table/async';
import { mapFilters, mapSort } from './utils';

const CandidateInvitationsList = () => {
    const [data, setData] = useState<CandidateInviteListType[]>([]);
    const [loading, setLoading] = useState(true);
    const [clearServerSearch, setClearServerSearch] = useState(false);
    const [start, setStart] = useState(1);
    const [limit, setLimit] = useState(10);
    const [filters, setFilters] = useState<{ [key: string]: string } | null>(null);
    const [sort, setSort] = useState<{ [key: string]: SortTypeEnum } | null>(null);
    const [totalCount, setTotalCount] = useState(0);

    const setStatusMessage = (status: number) => {
        switch (status) {
            case 0:
                return 'Joined';

            case 1:
                return 'Uploaded';

            case 2:
                return 'Pending';

            case 3:
                return 'Deleted';

            case 4:
                return 'Invite Sent';

            default:
                return '';
        }
    };

    const columns = useMemo(
        () => [
            {
                dataIndex: 'fullName',
                title: 'Candidate Name',
                render: (item: any) => (
                    <>
                        <div className="d-flex">
                            <div className="offer-table-reoffered me-2">
                                <img src={item.avatarUrl || avatar} alt="" className="avatar avatar--xs" />
                            </div>
                            <div className="text-crop" style={{ top: '6px' }}>
                                <div className="big-text text-capitalize" title={item.fullName}>
                                    {item.fullName}
                                </div>
                            </div>
                        </div>
                    </>
                ),
            },
            {
                dataIndex: 'email',
                title: 'Candidate Email',
                render: (item: any) => (
                    <>
                        <div className="text-crop" style={{ top: '6px' }}>
                            <div className="big-text text-capitalize" title={item.email}>
                                {item.email}
                            </div>
                        </div>
                    </>
                ),
            },
            {
                dataIndex: 'phone',
                title: 'Contact Number',
                // hideSort: true,
                render: (item: any) => (
                    <div className="text-nowrap text-ellipsis text-capitalize" title={item?.phone}>
                        {item?.phone ? <>{item?.phone}</> : 'NA'}
                    </div>
                ),
            },
            {
                dataIndex: 'invitedDate',
                title: 'Date Invited',
                // width: '128px',
                inputFilter: (value: string) => {
                    // Normalize to lowercase for comparison
                    return value.trim().toLowerCase();
                },

                render: (item: any) =>
                    new Date(item.invitedDate).toLocaleString('en-in', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                    }),
            },
            {
                dataIndex: 'candidateStatus',
                title: 'Status',
                // hideSort: true,
                // width: '128px',
                // inputFilter: (value: any) => {
                //     return getJoinStatusText(value);
                // },
                render: (item: any) => (
                    <>
                        <div className="text-ellipsis text-capitalize" title={setStatusMessage(item.candidateStatus)}>
                            <span style={{ color: `${getStatusColor(item.candidateStatus)}` }}>
                                {setStatusMessage(item.candidateStatus)}
                            </span>
                        </div>
                    </>
                ),
            },
        ],
        []
    );

    // const getList = useCallback(async () => {
    //     try {
    //         !loading && setLoading(true);

    //         const params: CandidateInviteAdvancedRequestType = {
    //             Start: start,
    //             Limit: limit,
    //             ...filters,
    //             ...sort,
    //         };

    //         const response = await candidateService.getCandidateInviteList(params);
    //         const { resultObject } = response;

    //         if (resultObject && Array.isArray(resultObject.items)) {
    //             // Filter the items based on 'InvitedDate' and 'CandidateStatus' fields
    //             const filteredItems = resultObject.items.filter((item: any) => {
    //                 // Filter for InvitedDate
    //                 if (filters?.InvitedDate) {
    //                     const filterValue = filters.InvitedDate.toLowerCase();
    //                     const itemDate = new Date(item.invitedDate)
    //                         .toLocaleDateString('en-IN', {
    //                             day: 'numeric',
    //                             month: 'short',
    //                             year: 'numeric',
    //                         })
    //                         .toLowerCase();

    //                     if (!itemDate.includes(filterValue)) {
    //                         return false;
    //                     }
    //                 }

    //                 // Filter for CandidateStatus
    //                 if (filters?.CandidateStatus) {
    //                     const filterStatus = parseInt(filters.CandidateStatus, 10);
    //                     if (item.candidateStatus !== filterStatus) {
    //                         return false;
    //                     }
    //                 }

    //                 return true;
    //             });

    //             setData(filteredItems);

    //             setTotalCount(count);
    //         } else {
    //             console.error('resultObject.items is not an array');
    //         }
    //     } catch (err) {
    //         console.error('Error fetching candidate invite list:', err);
    //     } finally {
    //         setLoading(false);
    //     }
    // }, [start, limit, filters, sort, loading]);

    const getList = useCallback(async () => {
        try {
            setLoading(true);

            const params: CandidateInviteAdvancedRequestType = {
                Start: start,
                Limit: limit,
                ...filters,
                ...sort,
            };

            const {
                resultObject: { items, count },
            } = await candidateService.getCandidateInviteList(params);

            const filteredItems = items.filter((item: any) => {
                if (filters?.InvitedDate) {
                    const filterValue = filters.InvitedDate.toLowerCase();
                    const itemDate = new Date(item.invitedDate)
                        .toLocaleDateString('en-IN', {
                            day: 'numeric',
                            month: 'short',
                            year: 'numeric',
                        })
                        .toLowerCase();

                    if (!itemDate.includes(filterValue)) {
                        return false;
                    }
                }

                if (filters?.CandidateStatus) {
                    const filterStatus = parseInt(filters.CandidateStatus, 10);
                    if (item.candidateStatus !== filterStatus) {
                        return false;
                    }
                }

                return true;
            });

            setData(filteredItems);
            setTotalCount(count);
        } catch (err) {
            console.error('Error fetching candidate invite list:', err);
        } finally {
            setLoading(false);
        }
    }, [start, limit, filters, sort]);

    useEffect(() => {
        (async function () {
            await getList();
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [start, limit, filters, sort]);

    return (
        <>
            <div className="company-page-contener employer-table">
                {/* {loading ? (
                    <PageLoader />
                ) : (
                    <>
                        <Table columns={columns} data={data} />
                    </>
                )} */}
                <button
                    onClick={() => {
                        setFilters(mapFilters([]));
                        sessionStorage.clear();
                        setClearServerSearch(true);
                    }}
                    className="btn btn-outline-primary btn-sm me-2 sticky-left"
                >
                    <i className="bi bi-funnel"></i> Clear All
                </button>
                <AsyncTable
                    loading={loading}
                    columns={columns}
                    data={data}
                    start={start}
                    setStart={setStart}
                    pageSize={limit}
                    clearServerSearch={clearServerSearch}
                    setClearServerSearch={setClearServerSearch}
                    setPageSize={setLimit}
                    totalCount={totalCount}
                    makeSearchRequest={(filters) => setFilters(mapFilters(filters))}
                    makeSortRequest={(value) => setSort(mapSort(value))}
                />
            </div>
        </>
    );
};

export default CandidateInvitationsList;

const getStatusColor = (value: CandidateInviteStatusEnum) => {
    switch (value) {
        case CandidateInviteStatusEnum.joined:
            return '#107c10';
        case CandidateInviteStatusEnum.uploaded:
            return '#ffa500';
        case CandidateInviteStatusEnum.pending:
            return '#ffa500';
        case CandidateInviteStatusEnum.deleted:
            return '#ffa500';
        case CandidateInviteStatusEnum.invited:
            return '#ffa500';
        default:
            return '';
    }
};
