import { TableFilterType, TableSortType } from '../../../components/table/table-object';
import { SortTypeEnum } from '../../../types';

export function mapFilters(filters: TableFilterType[]) {
    if (!filters.length) return {};
    let mappedFilters: { [key: string]: string } = {};
    filters.forEach((item) => {
        if (item.dataIndex === 'serviceType') {
            mappedFilters['ServiceType.FilterValue'] = item.value;
        }
        if (item.dataIndex === 'doneBy') {
            mappedFilters['DoneBy.FilterValue'] = item.value;
        }
        if (item.dataIndex === 'points') {
            mappedFilters['Points.FilterValue'] = item.value;
        }
        if (item.dataIndex === 'totalPoints') {
            mappedFilters['TotalPoints.FilterValue'] = item.value;
        }
        if (item.dataIndex === 'date' && item.value) {
            mappedFilters['Date.FilterValue'] = item.value.trim();
        }
    });
    return mappedFilters;
}

export function mapSort(sort: TableSortType) {
    let mappedSort: { [key: string]: SortTypeEnum } = {};
    if (sort.dataIndex === 'serviceType') {
        mappedSort['ServiceType.SortType'] = sort.value;
    }
    if (sort.dataIndex === 'doneBy') {
        mappedSort['DoneBy.SortType'] = sort.value;
    }
    if (sort.dataIndex === 'points') {
        mappedSort['Points.SortType'] = sort.value;
    }
    if (sort.dataIndex === 'totalPoints') {
        mappedSort['TotalPoints.SortType'] = sort.value;
    }
    if (sort.dataIndex === 'createdDate') {
        mappedSort['CreatedDate.SortType'] = sort.value;
    }
    return mappedSort;
}
