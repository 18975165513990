import React from 'react';
import { TableDataType } from './table-object';

type Props = {
    pageSize: number;
    setPageSize: (page: number) => void;
    start: number;
    setStart: (start: number) => void;
    paginationCurrent: number;
    paginationArray: TableDataType[][];
    handlePrevPage: () => void;
    handleNextPage: () => void;
    totalRecords?: number;
};

const Pagination: React.FC<Props> = ({
    pageSize,
    setPageSize,
    start,
    setStart,
    paginationCurrent,
    paginationArray,
    handlePrevPage,
    handleNextPage,
    totalRecords = 0,
}) => {
    const totalPages = Math.ceil(totalRecords / pageSize);

    return (
        <div className="row mt-3 mobile-changes">
            <div className="col-lg-8 col-7">
                <label className="fs-14">Items per page:</label>
                <select
                    value={String(pageSize)}
                    onChange={(ev) => {
                        setPageSize(Number(ev.target.value));
                        const temp_currentPage = Math.floor(start / Number(ev.target.value));
                        const temp_start = temp_currentPage * Number(ev.target.value) + 1;
                        setStart(temp_start);
                    }}
                    className="items-selecter fs-14 cursor-pointer"
                >
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="25">25</option>
                </select>
            </div>
            <div className="col-lg-4 col-5 text-end">
                <label htmlFor="" className="fs-14">
                    {/* Page {paginationCurrent + 1} of {paginationArray.length > 0 ? paginationArray.length : 1} */}
                    Page {paginationCurrent + 1} of {totalPages}
                </label>
                {/* {!!paginationArray[paginationCurrent - 1] && (
                    <button type="button" onClick={handlePrevPage} className="lt-action-btn">
                        <i className="bi bi-chevron-left" />
                    </button>
                )}
                {!!paginationArray[paginationCurrent + 1] && (
                    <button type="button" onClick={handleNextPage} className="lt-action-btn">
                        <i className="bi bi-chevron-right" />
                    </button>
                )} */}
                {paginationCurrent > 0 && (
                    <button type="button" onClick={handlePrevPage} className="lt-action-btn">
                        <i className="bi bi-chevron-left" />
                    </button>
                )}
                {paginationCurrent < totalPages - 1 && (
                    <button type="button" onClick={handleNextPage} className="lt-action-btn">
                        <i className="bi bi-chevron-right" />
                    </button>
                )}
            </div>
        </div>
    );
};

export default Pagination;
