import { axiosInstance } from './index';
import {
    CompanyListRequestType,
    CompanyListResponseType,
    CompanyProfileResponseType,
    CompanyReportFromCandidateListResponseType,
    CompanyReportFromCandidateRequestType,
    CompanyCountResponseType,
    CompanyVerificationListResponseType,
    ScreenXCompanyVerificationListResponseType,
    ScreenXCompanyListResponseType,
    ScreenXCompanyLeadListResponseType,
    RewardsListResponseType,
    RewardsRequestType,
    RewardCountResponseType,
    CandidateMatchRequestType,
    CandidateMatchResponseType,
    CandidateMatchedListResponseType,
} from '../../types/company';

export const companyService = {
    async getAllList(params: CompanyListRequestType) {
        // console.log(params, 'data params');
        // const { data } = await axiosInstance.get('Company', {
        //     params,
        // });
        // return data as CompanyListResponseType;
        const { data } = await axiosInstance.get('Company', {
            params,
        });
        return data as CompanyListResponseType;
    },

    async getAllHRMSList() {
        const { data } = await axiosInstance.get('HRMSCompany');
        return data as CompanyListResponseType;
    },

    async getReportFromCandidateList(params: CompanyReportFromCandidateRequestType) {
        const { data } = await axiosInstance.get('CompanySuspension/GetAllCompanyReportFromCandidate', {
            params,
        });
        return data as CompanyReportFromCandidateListResponseType;
    },

    async getId(id: string) {
        const { data } = await axiosInstance.get(`Company/${id}`);
        return data as CompanyProfileResponseType;
    },

    async updateFlag(params: { companyReportFromCandidateId: string; flagValue: boolean }) {
        await axiosInstance.post('CompanySuspension/UpdateFlag', params);
    },

    async getCount() {
        const { data } = await axiosInstance.get('Company/GetCompaniesTotalCount');
        return data as CompanyCountResponseType;
    },

    async getAllCompanyLeadsList(params: CompanyListRequestType) {
        const { data } = await axiosInstance.get('CompanyLeads', {
            params,
        });
        return data as CompanyListResponseType;
    },

    async getVerificationCompanyList() {
        // console.log(params, 'data params');
        // const { data } = await axiosInstance.get('Company', {
        //     params,
        // });
        // return data as CompanyListResponseType;
        const { data } = await axiosInstance.post('Company/GetVerificationCompaniesScreenX');
        return data as ScreenXCompanyVerificationListResponseType;
    },

    async getScreenXCompanyList() {
        // console.log(params, 'data params');
        // const { data } = await axiosInstance.get('Company', {
        //     params,
        // });
        // return data as CompanyListResponseType;
        const { data } = await axiosInstance.post('Company/GetCompanyListScreenX');
        return data as ScreenXCompanyListResponseType;
    },
    async getAllScreenXCompanyLeadsList() {
        const { data } = await axiosInstance.post('Company/GetCompanySalesLeadsScreenX');
        return data as ScreenXCompanyLeadListResponseType;
    },

    async getRewardsList(params: RewardsRequestType) {
        const { data } = await axiosInstance.get('Credits/GetCreditsInfoPaginatedList', {
            params,
        });
        return data as RewardsListResponseType;
    },

    async getRewardCount(params: { companyId: string }) {
        const { data } = await axiosInstance.get('Credits/GetCompanyCreditsTotalCount', { params });
        return data as RewardCountResponseType;
    },

    async getCandidateMatchList(params: CandidateMatchRequestType) {
        const { data } = await axiosInstance.get('Company/GetMatchTypeResults', { params });
        return data as CandidateMatchedListResponseType;
    },
};
