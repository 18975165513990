import { axiosInstance } from './index';
import {
    CandidateProfileResponseType,
    CandidateAdvancedRequestType,
    CandidateListResponseType,
    CandidateGlobalRequestType,
    GetAllCompanyReportFromCandidateResponseType,
    updateFlagType,
    candidatetResponseType,
    ScreenXCandidateListResponseType,
    CandidateInviteGlobalRequestType,
    CandidateInviteListResponseType,
    MatchListRequestType,
    MatchListResponseType,
    MatchRequestType,
} from '../../types/candidate';

export const candidateService = {
    async getList(params: CandidateGlobalRequestType) {
        const { data } = await axiosInstance.get('candidate', {
            params,
        });
        return data as CandidateListResponseType;
    },

    async getCandidateInviteList(params: CandidateInviteGlobalRequestType) {
        const { data } = await axiosInstance.get('candidate/GetBulkInvitedCandidatesPaginatedList', {
            params,
        });
        return data as CandidateInviteListResponseType;
    },

    async getMatchList(params: MatchRequestType) {
        const { data } = await axiosInstance.get('candidate/GetCandidateMatchStatus', {
            params,
        });
        return data as MatchListResponseType;
    },

    async getReportFromCompanyList(params: CandidateGlobalRequestType) {
        const { data } = await axiosInstance.get('CandidateSuspension/GetAllCandidateReportFromCompany', {
            params,
        });
        return data as GetAllCompanyReportFromCandidateResponseType;
    },

    async getListWithAnd(params: CandidateAdvancedRequestType) {
        const { data } = await axiosInstance.get('candidate/GetPaginatedListWithAnd', {
            params,
        });
        return data as CandidateListResponseType;
    },

    async getId(id: string) {
        const { data } = await axiosInstance.get(`candidate/${id}`);
        return data as CandidateProfileResponseType;
    },

    async getProfile() {
        const { data } = await axiosInstance.get(`Candidate/GetMyProfile`);
        return data as CandidateProfileResponseType;
    },

    async update(data: FormData) {
        await axiosInstance.put(`Candidate/UpdateMyCandidateProfile`, data);
    },

    async updateBanner(data: FormData) {
        await axiosInstance.put(`Candidate/UpdateCandidateProfileBannerImage`, data);
    },

    async deleteBanner() {
        await axiosInstance.put(`Candidate/DeleteCandidateProfileBannerImage`);
    },

    async updateAvatar(data: FormData) {
        await axiosInstance.put(`Candidate/UpdateCandidateAvatar`, data);
    },

    async deleteAvatar() {
        await axiosInstance.put(`Candidate/DeleteCandidateAvatar`);
    },

    async deleteResume() {
        await axiosInstance.put(`Candidate/DeleteCandidateResume`);
    },

    async updateFlag(params: updateFlagType) {
        await axiosInstance.post('CandidateSuspension/UpdateFlag', params);
    },

    async getCount() {
        const { data } = await axiosInstance.get('Candidate/GetCandidatesTotalCount');
        return data as candidatetResponseType;
    },

    async getScreenXCandidateList() {
        const { data } = await axiosInstance.post('candidate/GetCandidateListScreenX');
        return data as ScreenXCandidateListResponseType;
    },
};
