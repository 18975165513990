import React, { useState } from 'react';
import PageLoader from '../../components/loader';
import CandidateInvitationsList from '.';

const CandidateInviteLanding = () => {
    const [loading, setLoading] = useState(false);
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <h1 className="">Candidate Invitations</h1>
                </div>
            </div>
            {loading ? (
                <PageLoader />
            ) : (
                <>
                    <div className="card">
                        <div className="candidate-invite-table">
                            <CandidateInvitationsList />
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default CandidateInviteLanding;
